import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import EllisHelmet from '../components/EllisHelmet'

import { locationData } from '../assets/locationData.js'

class Visit extends React.Component
{
	render()
	{
		const currentPage = "visit";
		const title = 'Visit (Location & Hours)';

		const locations = locationData.locations.map((location) =>
		{
			const url = '/' + location.id + '/menu';

			const title = <h3>{location.name}</h3>;

			const picture = (location.picture ?
				<img className="location" src={location.picture} alt={location.name} />
				: null);

			const visitButton = <Link className="button primary" href={url}>Visit</Link>;

			const phonehref = "tel://" + location.phone.replace(/[\D]+/g, '');

			const address = (location.address ?
				<p>
						{location.address.street}<br />
						{location.address.street2 ? <React.Fragment>{location.address.street2}<br /></React.Fragment> : null}
						{location.address.city}, {location.address.state} {location.address.zip}<br />
						<span class="desktop-only">{location.phone}</span>
						<a class="mobile-only" href={phonehref}>{location.phone}</a>
				</p>
				: null);

			const hours = (location.hours ?
				<table>
					<tbody>
						{location.hours.map((date) =>
						{
							return <tr key={date.day}><th>{date.day}</th><td>{date.hours}</td></tr>;
						})}
					</tbody>
				</table>
				: null);

			const mapEmbedURL = (location.mapEmbedURL ?
				<iframe src={location.mapEmbedURL} title={location.name + " Map"} frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"></iframe>
				: null);

			const byline = (location.byline ? <h4>{location.byline}</h4> : null);

			return (<div className="row">
				<div className="col-4 col-12-medium centered">
					{picture}
					{visitButton}
				</div>
				<div className="col-4 col-12-medium">
					{title}
					{address}
					{mapEmbedURL}
				</div>
				<div className="col-4 col-12-medium">
					{byline}
					{hours}
				</div>
			</div>);
		});

		return (
			<Layout className="menuPage" location="visit" hideLocationAndHours>
				<EllisHelmet page={currentPage} title={title} location={null} />
				<div id="visitpage" className="wrapper style1">
					<div className="container">
						<header className="major">
							<h2>{locations.length} Locations To Choose From</h2>
						</header>
						<div className="alt">
							{locations}
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Visit